<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    controller: ReturnType<typeof useModal>;
    displayTotal?: number;
  }>(),
  { displayTotal: 5 },
);
const { displayTotal, controller } = toRefs(props);
const { isOpen, close: _close, toggle } = controller.value;

const queryString = ref("");

function search() {
  if (!queryString.value) return;
  navigateTo(`/search?search=${queryString.value}`);
}

function close() {
  _close();
  queryString.value = "";
}
</script>

<template>
  <div
    tabindex="0"
    class="xl:hidden size-10 cursor-pointer"
    :class="{ 'custom-underline': isOpen }"
    @click="toggle"
  >
    <div class="h-full mx-auto w-5 i-sl-search-alternate"></div>
  </div>
  <LayoutSidebar
    :controller="controller"
    side="left"
    layer-class="z-1500"
    class="bg-scheppach-primary-50! text-scheppach-primary-700 text-left"
  >
    <div class="bg-white px-5 pt-4 pb-lg flex items-center gap-8 shadow">
      <input
        ref="searchInput"
        v-model="queryString"
        type="text"
        class="h-10 grow px-sm b-scheppach-primary-700 text-scheppach-primary-700 font-700 border-b focus:outline-none"
        placeholder="Wonach suchen Sie?"
        @keydown.enter.prevent="search"
      />
      <button type="button" class="size-5 i-sl-close" @click="close"></button>
    </div>

    <div class="px-5 pt-6">
      <LayoutSearchResult
        :typing-query="queryString"
        :display-total="displayTotal"
      />
    </div>
  </LayoutSidebar>
</template>
